import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Quote = makeShortcode("Quote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "calm-versus-conflict"
    }}>{`Calm versus conflict`}</h1>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/18.svg" alt="calm hero image" mdxType="SingleImage" />
    <Quote quote="“People who fly into a rage always make a bad landing.”" author="Will Roger" mdxType="Quote" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      